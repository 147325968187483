'use client'
import React, { useContext, useEffect, useState } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Space, Switch } from 'antd'
import { ThemeContext } from 'src/providers/ThemeProvider'

const ThemeToggleButton = () => {
  const { theme, setTheme } = useContext(ThemeContext)
  const [isLight, setIsLight] = useState(false)
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
  }

  useEffect(() => {
    setIsLight(theme === 'light' ? true : false)
  }, [theme])

  return (
    <Space direction="vertical">
      <Switch
        checkedChildren="Boy"
        unCheckedChildren="Girl"
        checked={!isLight}
        onChange={toggleTheme}
      />
    </Space>
  )
}

export default ThemeToggleButton
