import React, { useCallback, useEffect, useRef } from 'react'
import styles from './styles.module.css'
import CurrentPlanLabel from '../NavBar/CurrentPlanLabel'
import { useDispatch, useSelector } from 'react-redux'
import { setIsTariffModalOpened } from '@/store/Features/UISlice'
import classNames from 'classnames'

const ToolWrapper = ({
  children,
  title,
  btnCallback,
  avaliablePlan = 'Free',
  isMobile = false,
  fullWidthContent = false,
}) => {
  const dispatch = useDispatch()
  const toolWrapperRef = useRef(null)
  const currentPlan = useSelector(state => state.user.currentPlan.planName)
  const plansPriority = {
    Free: 0,
    Premium: 1,
    Ultimate: 2,
  }
  const isAvaliable = plansPriority[currentPlan] >= plansPriority[avaliablePlan]

  const findFilterContainer = useCallback(() => {
    const container = toolWrapperRef.current
    if (container) {
      return container.querySelector('.scroll')
    }
  }, [])
  useEffect(() => {
    if (!isMobile) return
    const container = toolWrapperRef.current
    const handleWheel = event => {
      if (container) {
        const element = findFilterContainer()
        event.preventDefault()
        requestAnimationFrame(() => {
          element.scrollTo({
            left: element.scrollLeft + event.deltaY,
          })
        })
      }
    }

    container.addEventListener('wheel', handleWheel, { passive: false })

    return () => {
      container.removeEventListener('wheel', handleWheel)
    }
  }, [findFilterContainer, isMobile])

  return (
    <div
      className={classNames(styles.toolWrapper, {
        [styles.fullWidth]: fullWidthContent,
      })}
    >
      <div>{title}</div>
      <div
        ref={toolWrapperRef}
        className={classNames(styles.toolContent, {
          [styles.fullWidth]: fullWidthContent,
          [styles.scroll]: isMobile,
        })}
        onClick={
          isAvaliable
            ? btnCallback
              ? e => btnCallback(e)
              : null
            : () => dispatch(setIsTariffModalOpened(true))
        }
      >
        <div
          className={classNames({
            [styles.fullWidth]: fullWidthContent,
          })}
        >
          {children}
        </div>
        {!isAvaliable && (
          <button className={styles.btn}>
            <CurrentPlanLabel lablel={'Ultimate'} />
          </button>
        )}
      </div>
    </div>
  )
}

export default ToolWrapper
