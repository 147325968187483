import { InputNumber, Slider } from 'antd'
import React, { useEffect, useState } from 'react'
import useImageOpacity from '@/hooks/useImageOpacity'
import { useTranslations } from 'next-intl'
import ToolWrapper from '../../ToolWrapper'

const getOpacityFromObject = activeObjectPrefs => {
  return activeObjectPrefs.opacity * 100
}

const Opacity = ({
  styles,
  canvas,
  activeObjectPrefs,
  initialLoad,
  isMobile,
}) => {
  const t = useTranslations('CanvasTool')
  const activeObject = canvas.getActiveObject()
  const [currentOpacity, setCurrentOpacity] = useState(
    getOpacityFromObject(activeObjectPrefs),
  )
  const applyOpacity = useImageOpacity({
    value: currentOpacity,
    canvas,
  })

  const handleChangeOpacity = value => {
    setCurrentOpacity(value)
    canvas.fire('object:modified', { target: activeObject })
  }

  useEffect(() => {
    if (!initialLoad) {
      applyOpacity()
    }
  }, [applyOpacity, initialLoad])

  const blockStyle = !isMobile
    ? styles.styleBlockLine
    : styles.styleBlockLineMobile

  return (
    <ToolWrapper title={t('opacity')} fullWidthContent={true}>
      {(currentOpacity || currentOpacity === 0) && (
        <div className={styles.rangeInput}>
          <InputNumber
            className={styles.inputAnt}
            min={0}
            max={100}
            value={currentOpacity}
            onChange={handleChangeOpacity}
          />
          <Slider
            className={styles.range}
            step={5}
            min={0}
            max={100}
            value={currentOpacity}
            onChange={handleChangeOpacity}
          />
        </div>
      )}
    </ToolWrapper>
  )
}

export default Opacity
