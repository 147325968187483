import Opacity from '@/ui/components/ObjectPreferences/Opacity'

export const UIIcons = {
  loadImage: '/icons/loadImage.svg',
  addText: '/icons/addText.svg',
  addTextActive: '/icons/addTextActive.svg',
  text: '/icons/text.svg',
  textActive: '/icons/textActive.svg',
  saveActive: '/icons/saveActive.svg',
  saveInactive: '/icons/saveInactive.svg',
  addCircle: '/icons/addCircle.svg',
  freeDraw: '/icons/freeDraw.svg',
  freeDrawActive: '/icons/freeDrawActive.svg',
  cyrcle: '/icons/cyrcle.svg',
  cyrcleActive: '/icons/cyrcleActive.svg',
  rect: '/icons/rect.svg',
  rectActive: '/icons/rectActive.svg',
  shapes: '/icons/ph_shapes.svg',
  shapesActive: '/icons/ph_shapes_active.svg',
  sticker: '/icons/sticker.svg',
  stickerActive: '/icons/stickerActive.svg',
  loadCanvas: '/icons/loadCanvas.svg',
  saveToPDF: '/icons/saveToPDF.svg',
  pickColor: '/icons/pickColor.svg',
  addSquare: '/icons/addSquare.svg',
  burger: '/icons/iconamoon_menu-burger-horizontal.svg',
  backArrow: '/icons/backArrow.svg',
  pdf: '/icons/pdf.svg',
  share: '/icons/share.svg',
  edit: '/icons/edit.svg',
  imageGallary: '/icons/image-gallery.svg',
  imageGallaryActive: '/icons/image-gallery-active.svg',
  stylesSettings: '/icons/styles-settings.svg',
  menuDots: '/icons/menu-dots.svg',
  slidesPanel: '/icons/slidesPanel.svg',
  canvasSettings: '/icons/canvasSettings.svg',
  chevronLeft: '/icons/Chevron_Left.svg',
  settings: '/icons/settings.svg',
  burgerHeader: '/icons/burger.svg',
}

export const UILanding = {
  doneArrowMini: '/icons/doneArrowMini.svg',
}

export const toolIcons = {
  brightness: '/icons/tools/brightness.svg',
  mask: '/icons/tools/mask.svg',
  crop: '/icons/tools/crop.svg',
  photoFilters: '/icons/tools/photo-filter.svg',
  colors: '/icons/tools/colors.svg',
  fontFamily: '/icons/tools/fontFamily.svg',
  fontWeight: '/icons/tools/fontWeight.svg',
  fontSize: '/icons/tools/fontSize.svg',
  replaceImage: '/icons/tools/replaceImage.svg',
  deleteTrash: '/icons/delete-trash.svg',
  removeBackground: '/icons/tools/removeBackground.svg',
  opacity: '/icons/tools/opacity.svg',
  textAlignLeft: '/icons/tools/text-align-left.svg',
  textAlignCenter: '/icons/tools/text-align-center.svg',
  textAlignRight: '/icons/tools/text-align-right.svg',
  textAlignJustify: '/icons/tools/text-align-justify.svg',
}
