import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
const { fabric } = require('fabric')
import { setActiveObjectPrefs } from '@/store/Features/canvasSlice'

const useImageOpacity = ({ value, canvas }) => {
  const dispatch = useDispatch()
  const applyOpacity = useCallback(() => {
    const activeObject = canvas.getActiveObject()
    if (activeObject) {
      // Создаем фильтр, если его нет
      activeObject.set({
        opacity: value / 100,
      })
      dispatch(setActiveObjectPrefs(activeObject.toObject()))
      canvas.renderAll()
    }
  }, [canvas, dispatch, value])
  return applyOpacity
}
export default useImageOpacity
