const antdColors = {
  light: {
    token: {
      colorPrimary: '#6200ea',
    },
    components: {
      Button: {
        colorPrimary: '#bf9895',
        colorPrimaryHover: '#e2cecc',
        colorPrimaryActive: '#6200ea',
        // colorBgContainerDisabled: '#0000ff',
        colorBorder: 'transparent',
      },
      Dropdown: {
        colorBgElevated: '#bf9895',
        color: '#6200ea',
        colorText: '#ffffff',
      },
      Input: {
        inputFontSize: '28px',
        activeBg: '#FFFFFF',
        activeBorderColor: '#FFFFFF00',
        hoverBorderColor: '#FF000000',
      },
      Tabs: {
        inkBarColor: '#e2cecc',
        itemActiveColor: '#e2cecc',
        itemSelectedColor: '#bf9895',
      },
    },
  },
  dark: {
    token: {
      colorPrimary: '#44e604',
    },
    components: {
      Button: {
        colorPrimary: '#6d79ab',
        colorPrimaryHover: '#d7dcf0',
        colorPrimaryActive: '#44e604',
        // colorBgContainerDisabled: '#0000ff',
        colorBorder: 'transparent',
      },
      Dropdown: {
        colorBgElevated: '#6d79ab',
        color: '#44e604',
        colorText: '#ffffff',
      },
      Input: {
        inputFontSize: '28px',
        activeBg: '#FFFFFF',
        activeBorderColor: '#FFFFFF00',
        hoverBorderColor: '#FF000000',
      },
      Tabs: {
        inkBarColor: '#6d79ab',
        itemActiveColor: '#6d79ab',
        itemSelectedColor: '#6d79ab',
      },
    },
  },
  tariffModal: {
    token: {
      colorPrimary: '#6200ea',
    },
    components: {
      Modal: {
        headerBg: 'transparent',
        titleFontSize: '32px',
        fontWeightStrong: 300,
        titleColor: '#ffffff',
      },
    },
  },
}

export default antdColors
// {
//     light:  {
//         --text-color: #000000;
//         --primary-color: #6200ea;
//         --secondary-color: #03dac6;
//         --main-bg-landing-color: #d8bcba;
//         --main-bg-landing-color-darker: #d8bcba;
//         --main-bg-landing-color-second: #d8bcba;
//       },

//       dark:  {
//         --background-color: #ffffff;
//         --text-color: #000000;
//         --primary-color: #cad1ed;
//         --secondary-color: #03dac6;
//         --main-bg-landing-color: #cad1ed;
//         --main-bg-landing-color-darker: #44e604;
//         --main-bg-landing-color-second: #cad1ed;
//       }
// }
