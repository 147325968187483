import React from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { setIsTariffModalOpened } from 'src/store/Features/UISlice'

const CurrentPlanLabel = ({ lablel }) => {
  const currentPlan = useSelector(state => state.user.currentPlan.planName)
  const dispatch = useDispatch()
  const handleOpenTariffs = () => {
    dispatch(setIsTariffModalOpened(true))
  }
  return (
    <div className={styles.currentPlan} onClick={handleOpenTariffs}>
      {lablel ? lablel : currentPlan}
    </div>
  )
}

export default CurrentPlanLabel
