import { Button, ConfigProvider, Dropdown, Space } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useLocale, useTranslations } from 'next-intl'
import React, { useContext, useEffect, useState } from 'react'
import { setUserLocale } from 'src/utils/locale'
import styles from './styles.module.css'
import { ThemeContext } from '@/providers/ThemeProvider'
import antdColors from 'src/constants/colors'

const LanguageDropdown = () => {
  const [themeConfig, setThemeConfig] = useState({})
  const { theme, setTheme } = useContext(ThemeContext)
  const locale = useLocale()
  const handleMenuClick = e => {
    setUserLocale(e.key)
  }

  const getCSSVariableValue = variable => {
    return getComputedStyle(document.documentElement).getPropertyValue(variable)
  }
  useEffect(() => {
    const primaryColor = getCSSVariableValue(
      '.' + theme + '.' + '--main-bg-landing-color-darker',
    ).trim()
    const primaryHoverColor = getCSSVariableValue(
      '--main-bg-landing-color',
    ).trim()

    setThemeConfig(antdColors[theme])
  }, [theme])

  const items = [
    {
      label: 'English',
      key: 'en',
      icon: <UserOutlined />,
    },
    {
      label: 'Русский',
      key: 'ru',
      icon: <UserOutlined />,
    },
  ]
  const menuProps = {
    items,
    onClick: handleMenuClick,
  }
  return (
    <ConfigProvider theme={antdColors[theme]}>
      <Dropdown menu={menuProps} trigger={['click']}>
        <Button type="primary">
          <Space>{locale}</Space>
        </Button>
      </Dropdown>
    </ConfigProvider>
  )
}

export default LanguageDropdown
